import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import betsyJustin8 from "../images/betsyJustin8.jpg";
import betsyJustin9 from "../images/betsyJustin9.jpg";
import betsyjustin10 from "../images/betsyjustin10.jpg";
import betsyJustin11 from "../images/betsyJustin11.jpg";
import betsyJustin12 from "../images/betsyJustin12.jpg";
import betsyJustin13 from "../images/betsyJustin13.jpg";
import betsyJustin14 from "../images/betsyJustin14.jpg";
import betsyJustin15 from "../images/betsyJustin15.jpg";
import betsyJustin16 from "../images/betsyJustin16.jpg";
import betsyJustin17 from "../images/betsyJustin17.jpg";
import betsyJustin18 from "../images/betsyJustin18.jpg";
import betsyJustin19 from "../images/betsyJustin19.jpg";
import betsyJustin20 from "../images/betsyJustin20.jpg";
import betsyJustin21 from "../images/betsyJustin21.jpg";
import betsyJustin22 from "../images/betsyJustin22.jpg";
import betsyJustin23 from "../images/betsyJustin23.jpg";
import betsyJustin24 from "../images/betsyJustin24.jpg";
import betsyJustin25 from "../images/betsyJustin25.jpg";
import betsyJustin26 from "../images/betsyJustin26.jpg";
import betsyJustin27 from "../images/betsyJustin27.jpg";
import betsyJustin28 from "../images/betsyJustin28.jpg";
import betsyJustin29 from "../images/betsyJustin29.jpg";
import betsyJustin30 from "../images/betsyJustin30.jpg";
import betsyJustin31 from "../images/betsyJustin31.jpg";
import betsyJustin32 from "../images/betsyJustin32.jpg";
import betsyJustin33 from "../images/betsyJustin33.jpg";
import betsyJustin34 from "../images/betsyJustin34.jpg";
import betsyJustin35 from "../images/betsyJustin35.jpg";
import betsyJustin36 from "../images/betsyJustin36.jpg";
import betsyJustin37 from "../images/betsyJustin37.jpg";
import betsyJustin38 from "../images/betsyJustin38.jpg";
import betsyJustin39 from "../images/betsyJustin39.jpg";
import betsyJustin40 from "../images/betsyJustin40.jpg";


function galleryPage() {
  return (
    <Layout>
      <SEO
        title="Gallery"
        keywords={[]}
      />
      <div class="container max-w-full">
        <div class="grid flex flex-wrap">
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin8} class="object-cover h-full w-full block" alt="" />
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin9} class="object-cover h-full w-full block" alt="" />
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyjustin10} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin11} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin12} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin13} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin14} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin15} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin16} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin17} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin18} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin19} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin20} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin21} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin22} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin23} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin24} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin25} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin26} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin27} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin28} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin29} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin30} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin31} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin32} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin33} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin34} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin35} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin36} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin37} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin38} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin39} class="object-cover h-full w-full block" alt=""/>
          </div>
          <div class="lg:w-4/12 md:w-2/4">
            <img src={betsyJustin40} class="object-cover h-full w-full block" alt=""/>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default galleryPage;
